<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Calendrier</h3>
        </div>
        <div class="column is-3 is-hidden-mobile">
          <ol class="breadcrumb">
            <li>
              <a href="/#" class="is-active">Home</a>
            </li>
            <li>
              <span class="active">Calendrier</span>
            </li>
          </ol>
        </div>
      </div>
      <div class="header">
        <p class="subtitle has-text-weight-semibold" :class="getClass(weekLetter)">Nous sommes en semaine {{ weekLetter }}.</p>
        <div class="legend">
          <p class="subtitle">Légende : </p>
          <div class="legendGrid">
            <div class="countDown">12</div>
            <div class="textLegend">Décompte volants de la semaine associée (A, B, C ou D)</div>
            <div class="closed">12</div>
            <div class="textLegend">Fermeture de la Louve</div>
          </div>
        </div>
      </div>
      <div class="months">
        <div class="month" v-for="(month, index) in months" v-bind:key="index">
            <month v-bind:date="month"></month>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Month from "@/components/Month";

export default {
  name: "Calendrier",
  data: function() {
    return {
        today: this.$moment(),
        months: []
    };
  },
  mounted() {
    //this.init()
    this.months = [...Array(24)].map((_, i) => {
        return this.$moment().startOf('year').add(i, 'month').startOf('month')
    })
    window.scrollTo(0,0);
  },
  computed: {
    weekLetter() {
      // On commence au Lundi 23/12/2019, première semaine A de référence.
      // diff('week') donne un nombre de semaines où une semaine = groupes de 7 jours (pas de notion de numéro de semaine)
      var weeksFrom2020 = this.$moment().diff(this.$moment(new Date('2019-12-23')), 'week');
      return (String.fromCharCode((weeksFrom2020 % 4) + "A".charCodeAt(0)));
    }
  },
  methods: {
    getClass(w) {
      return w.toLowerCase();
    }
  },
  components: {
      Month
  }
};
</script>

<style lang="css" scoped>
.is-title {
  text-transform: capitalize;
}

.subtitle:not(:last-child),
.title:not(:last-child) {
  margin-bottom: 1.5rem;
}

.title {
  color: #363636;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.125;
}

.level-item .subtitle,
.level-item .title {
  margin-bottom: 0;
}

.subtitle.is-5 {
  font-size: 1.25rem;
}

.subtitle {
  color: #2e2e2e;
  font-weight: 300;
  line-height: 1.25;
}

.subtitle strong {
  color: #363636;
}

.subtitle strong,
.title strong {
  font-weight: 500;
}

.level-item {
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-preferred-size: auto; */
  flex-basis: auto;
  /* -ms-flex-positive: 0; */
  flex-grow: 0;
  /* -ms-flex-negative: 0; */
  flex-shrink: 0;
  /* -ms-flex-pack: center; */
  justify-content: center;
}

.breadcrumb {
  --separator: "\2044";
  list-style: none;
  /* -ms-flex-align: center; */
  align-items: center;
  /* display: -ms-flexbox; */
  display: flex;
  /* -ms-flex-pack: end; */
  justify-content: flex-end;
}

.level-left .level-item:not(:last-child),
.level-right .level-item:not(:last-child) {
  margin-right: 0.75rem;
}

.is-vertical-center {
  text-align: center;
  padding-top: 10px;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .modal {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.months {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.month {
  width: 500px;
  margin: 0px auto 0px auto;
  padding: 20px;
}

.header {
  text-align: center;
}

.legend {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.legend > .subtitle {
  margin: 5px 30px 5px 30px;
}

.legendGrid {
  display: grid;
  grid-template-columns: 45px auto;
  grid-template-rows: 45px 45px 45px;
}

.closed{
  border-radius: 100%;
  margin: 5px;
  background:
    linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 48%,#737479 49%,#737479 50%,rgba(0,0,0,0) 51%,rgba(0,0,0,0) 100%),
    linear-gradient(135deg, #f2f6fa 0%,#f2f6fa 48%,#737479 49%,#737479 50%,#f2f6fa 51%,#f2f6fa 100%);
}

.countDown{
  background-color: #B5B6BA;
  color: black;
  border-radius: 100%;
  margin: 5px;
}

.closed, .countDown, .textLegend {
  display: flex;
  align-items: center;
  margin: 5px;
}

.closed, .countDown {
  justify-content: center;
}

.a, .e {
  color: #F5CF4D;
}
.b, .f {
  color: #20A4A0;
}
.c, .g {
  color: #f85f40;
}
.d, .h {
  color: #66a759;
}
</style>
